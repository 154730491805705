import React, { Component } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';

export default class Navigation extends Component {
  render() {
    return (
      <Container fluid="xxl">
        <Row>
          <Col></Col>
          <Col>
            <Navbar fixed="top">
              <Container>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
                <Navbar.Brand href="/">
                  <img 
                    src="https://assets.newperspectivetherapy.ca/logo.png" 
                    className='navbar-brand-logo' 
                    alt="New Perspective Counseling and Therapy Services" 
                  />
                </Navbar.Brand>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>
    );
  }
};