import styled from 'styled-components';

export const Styles = styled.div`
html, .body, .container {
  color:    black;
  width:    100%;
  height:   100%;
  margin:   0;
  position: relative;
}

.vertical-center {
  display:         flex;
  justify-content: right;
  align-items:     center;
  margin-top:      100px;
}

.project-list {
  margin-bottom: 25px;
}

.project-list p {
  display:     flex;
  align-items: center;
}

.navbar {
  display:         flex;
  width:           100%;
  padding-top:     10px;
  padding-right:   100px;
  justify-content: center;
}

.navbar-brand-logo {
  width:  360px;
  height: auto;
}

p {
  font-size:   1.5em;
  font-family: 'Gentium Plus', serif;
  color:       white;
}

h1, h3 {
  font-weight: bold;
}

h2 {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gentium Plus', serif;
  color:        white;
  width:        100%;
}

.page-copy {
  margin-top:  50px;
  position:    absolute;
  overflow-y:  scroll;
  height:      40vh;
  display:     flex;
  width:       100%
  overflow:    hidden;
  align-items: center;
  color:       white;
}

.404-img {
  justify-content: center;
  align-items:     center;
  width:           100px;
  height:          auto;
}

.profile-pic {
  image-width: 20%;
  width:       400px;
  height:      auto;
}

.nav-link {
  font-family:     'Gentium Plus', serif;
  font-size:       36px;
  color:           white !important;
  text-decoration: none;
  margin-left:     16px;
}

.form-label {
  font-family: 'Gentium Plus', serif;
  font-size:   1.3em;
}

.form-control {
  font-family:  'Gentium Plus', serif;
  font-size:     1.2em;
  border-radius: 0em;
}

button {
  font-family:   'Gentium Plus', serif;
  font-size:     1.2em;
  border-radius: 0em;
}

.logo-splash {
  display:        flex;
  align-items:    left;
  vertical-align: middle;
  width:          100%;
  padding-right:  60px;
}

.text-splash {
  display:         flex;
  justify-content: flex-end;
  align-items:     center;
  font-family:     'Gentium Plus', serif;
  font-size:       90px;
  line-height:     1;
}

.page-footer {
  width:  100%;
  margin: 0 auto;
}

.footer-copyright {
  font-family: 'Gentium Plus', serif;
  font-size:   20px;
}

.footer-copyright a {
  font-family:     'Gentium Plus', serif;
  font-size:       20px;
  color:           white !important;
  text-decoration: none;
}
`