import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class About extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
          <img 
              className="logo-splash" 
              src="https://assets.newperspectivetherapy.ca/coming_soon.png" 
              alt="Coming Soon" 
              style={{ marginTop: "100px" }}
            />
          </Col>
          <Col>
            <Row style={{ marginTop: "100px" }}>
              <h1>
                Contact Us
              </h1>
            </Row>
            <br />
            <Row>
              <p>
                Eventually you will be able to book appointments on this page.
              </p>
              <p>
                Until then you can email <a href="mailto:melissa.cr@newperspectivetherapy.ca">melissa.cr@newperspectivetherapy.ca</a> for more information.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };
};