import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class FourOhFour extends Component {
  render() {
    return (
      <Container>
        <Col>
          <Row>
            <h1 className='vertical-center'>Page Not Found</h1>
          </Row>
        </Col>
      </Container>
    );
  };
};