import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class About extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Row xs={2}>
              <img
                src="https://assets.newperspectivetherapy.ca/mel_profile.png" 
                alt="Melissa Costa-Ryan, BSW, RSW, Registered Social Worker" 
                width="75%"
                style={{ marginTop: "100px" }}
              />
            </Row>
            <Row xs={2}>
              <h1>Melissa Costa-Ryan</h1>
              <br />
              <h2>BSW, RSW</h2>
              <br />
              <h3>Registered Social Worker</h3>
            </Row>
          </Col>
          <Col>
          <Row style={{marginTop: "100px"}}>
            <h1>About</h1>
          </Row>
          <br />
          <Row className="page-copy">
            <p>
              I have been providing therapy in areas of mental health, specializing in trauma processing, anxiety, depression, PTSD, 
              self esteem & self worth and helping individuals who have experienced domestic violence and sexual assault. 
              I have also assisted youth with special needs and individuals with FASD. I also have substantial experience working 
              with diverse populations such as the LGBTQ2S+ community, Indigenous communities specifically First Nation, Inuit, 
              and Metis population.
            </p>
            <p>
              I focus on a client centred practice but I also recognize at times it takes a community to assist individuals, couples, 
              and families in their time of need. I work from a holistic approach focusing on the spiritual, mental, emotional, 
              and physical aspects of people I support.
            </p>
            <p>
              I strive to work side by side with clients and provide them with a safe non-judgemental space for them to process 
              their experiences, feelings, and emotions. Throughout my career, I always believed that learning about the similarities 
              and differences of other people and other cultures are vital in forming therapeutic relationships with people I support.
            </p>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };
};