import React, { Component } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

export default class Title extends Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet bodyAttributes={{
          // eslint-disable-next-line no-multi-str
          style: 'background-color: #88bdff; \
                  background-image: url("https://assets.newperspectivetherapy.ca/tim-chow-9IcKPSQ9G5Q-unsplash.jpg"); \
                  background-size: cover; \
                  background-repeat: no-repeat; \
                  background-blend-mode: multiply;'
        }}>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="New Perspective Counseling and Therapy Services" />
          <title>New Perspective Counseling and Therapy Services</title>
          <link rel="canonical" href="https://newperspectivetherapy.ca" />
          <link rel="icon" href="https://assets.newperspectivetherapy.ca/square_logo.png" />
          <style>@import url('https://fonts.googleapis.com/css2?family=Gentium+Plus&display=swap');</style> 
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
            integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
            crossorigin="anonymous"
          />
        </Helmet>
      </HelmetProvider>
    );
  }
};