import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class Home extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col xs={6}>
            <img 
              className="logo-splash" 
              src="https://assets.newperspectivetherapy.ca/home_stock.png" 
              alt="Therapy Session" 
              style={{ marginTop: "100px" }}
            />
          </Col>
          <Col xs={6}>
            <Row style={{ marginTop: "100px" }}>
              <h1>We Can Help You Gain A New Perspective</h1>
            </Row>
            <br />
            <Row>
              <p>
                Dealing with your past can be painful, but in a non-judgemental environment you can heal, grow and start life again. 
                Our counsellors are compassionate, supportive and understanding when it comes to your past experiences.  
                We work to develop a positive outlook as well as provide useful tools for you to be able to move forward.
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };
};