import React from 'react';
import Parser from 'html-react-parser';

const BoxingOctopusUrl = '<a href="https://boxingoctop.us" target="_blank" rel="noreferrer">Boxing Octopus Creative</a>'
const ReactUrl         = '<a href="https://reactjs.org" target="_blank" rel="noreferrer">React</a>'

const date =  new Date().getFullYear();

export const Footer = () => (
  <div className="fixed-bottom">
    <footer className="page-footer font-small pt4">
      <div className="footer-copyright text-center py-3">
        Built with {Parser(ReactUrl)} | Site Design Copyright © {date} {Parser(BoxingOctopusUrl)}
      </div>
    </footer>
  </div>
);